* {
  box-sizing: border-box;
}

body {
  margin: 8px;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  background-color: #282c34;
  min-height: 100vh;
  font-weight: 400;
  color: aliceblue;
}


p {
  max-width: 60ch;
  margin: 16px auto;
}

.letterBox,
.symbol {
  color: #282c34;
  font-size: 1.6rem;
  flex-direction: column;
  border-radius: 4px;
  border: 2px solid #ffffff;
  background-color: aliceblue;
  padding: 4px;
  margin: 2px;
  text-align: center;
  cursor: pointer;
  display: flex;
  min-width: 28px;
  font-weight: 700;
}

.letterDetails {
  margin-top: 4px;
  font-size: 0.8rem;
}

.lettersContainer,
.symbolsContainer {
  display: flex;
  max-width: 960px;
  flex-wrap: wrap;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
}

.symbol {
  background-color: antiquewhite;
  padding: 4px;
  margin: 2px;
}

.symbol.disabled {
  opacity: 0.3;
}

.wordInput {
  padding: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  max-width: 960px;
  margin: 0 auto;
}

.wordInput input {
  font-family: 'Roboto', sans-serif;
  font-size: 2rem;
  font-weight: 400;
  padding: 8px;
  border-radius: 6px;
  border: none;
  background: beige;
  margin: 4px;
  width: 100%;
  max-width: 640px;
}

.wordsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.wordsContainer span {
  display: inline-block;
  padding: 4px;
  margin: 2px 4px;
  border-radius: 3px;
  font-size: 1.2rem;
}

.word svg {
  cursor: pointer;
}